import React from "react"
import FAQ from "../components/faq"
import SEO from "../components/seo"

const FAQpage = () => (
  <>
    <SEO title="Testimonials" />
    <div className="hero-section in-pages-bg">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">Frequently Asked Questions</h3>
        <p className="is-white font-size-20">Looking for answers?</p>
      </div>
    </div>
    <FAQ />
  </>
)

export default FAQpage
